<template>
  <div>
    <v-tabs :value="value" light center-active @change="$emit('input', $event)">
      <v-tab v-for="tab in tabs" :key="tab.id" light> {{ tab.title }} </v-tab>
    </v-tabs>
    <v-divider />
  </div>
</template>

<script>
export default {
  name: 'EntityCardTabs',
  props: {
    value: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style></style>
