import linkedObjectSchema from '../api/linked-object.graphql';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { prepareObject } from '@/provider/utils';
import { mediaClient } from '@/provider';

export function useLinkedObject(linkedObjectId) {
  const { result, load, loading } = useLazyQuery(
    linkedObjectSchema.fetch,
    {
      id: linkedObjectId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const item = useResult(result, {}, data => ({
    ...prepareObject(data.object),
    pictureUrl: mediaClient.getImageUrl(data.object?.schema?.mPicture)
  }));

  return {
    load,
    item,
    loading
  };
}
