<template>
  <v-img
    cover
    :src="!src || useFallbackSrc ? fallbackSrc : src"
    height="240"
    width="100%"
    @error="useFallbackSrc = true"
  />
</template>

<script>
export default {
  name: 'EntityCardImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    fallbackSrc: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      useFallbackSrc: false
    };
  }
};
</script>

<style></style>
