<template>
  <div class="d-flex algin-center">
    <div class="mr-2" :class="{ 'red--text': !enabled }">
      {{ enabled ? 'Enabled' : 'Disabled' }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DatasetCardHeaderStatusBar',
  props: {
    enabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
