<template>
  <div class="d-flex algin-center">
    <div class="mr-2" :class="{ 'red--text': !enabled }">
      {{ enabled ? 'Enabled' : 'Disabled' }}
    </div>
    <div class="d-flex mr-2 flex-nowrap">
      <v-icon small :color="batteryIconColor">{{ batteryIcon }}</v-icon>
      <div v-if="showBatteryLevel" :style="`color: ${batteryIconColor}`">
        {{ +batteryLevel + '%' }}
      </div>
    </div>
    <v-icon v-if="statusIcon" class="mr-2" small :color="statusIconColor">{{
      statusIcon
    }}</v-icon>
    <v-icon v-if="emulation" small>
      $emulation
    </v-icon>
    <v-icon v-if="muted" class="mr-2" small v-text="'$mute'" />
    <v-icon v-if="showNoResponseIcon" class="mr-2 red--text" small>
      $status_no_response
    </v-icon>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api';
import { useDeviceStatus } from '@/modules/linked-objects/compositions/deviceStatus';

export default {
  name: 'DeviceCardHeaderStatusBar',
  props: {
    enabled: {
      type: Boolean,
      default: false
    },
    batteryLevel: {
      type: Number,
      default: 0
    },
    emulation: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: false
    },
    dcPower: {
      type: Boolean,
      default: false
    },
    batteryLow: {
      type: Boolean,
      default: false
    },
    showNoResponseIcon: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { batteryLow, dcPower } = toRefs(props);
    const {
      batteryIcon,
      batteryIconColor,
      statusIcon,
      statusIconColor,
      showBatteryLevel
    } = useDeviceStatus({
      batteryLow,
      dcPower
    });
    return {
      batteryIcon,
      batteryIconColor,
      statusIcon,
      statusIconColor,
      showBatteryLevel
    };
  }
};
</script>

<style></style>
