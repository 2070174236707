<template>
  <entity-card
    :tabs="tabs"
    :tabs-items="tabsItems"
    :title="item.name"
    :image="item.pictureUrl"
    fallback-image="/img/device.png"
    :show-back-btn="canBack"
    :actions="actions"
    :loading="loading"
    @click:close="handleClose"
    @click:back="handleBack"
  >
    <template #header:status-bar>
      <dataset-card-header-status-bar
        v-if="item.schemaType === 'dataset'"
        :enabled="item.enabled"
      />
      <device-card-header-status-bar
        v-else
        :battery-low="item.deviceBatteryLow && item.deviceBatteryLow.value"
        :enabled="item.enabled"
        :battery-level="
          item.deviceBatteryLevel && item.deviceBatteryLevel.value
        "
        :emulation="item.settingsEmulation && item.settingsEmulation.value"
        :muted="item.muted"
        :dc-power="item.deviceDcPower && item.deviceDcPower.value"
        :show-no-response-icon="
          item.deviceResponseStatus && !item.deviceResponseStatus.value
        "
      />
    </template>
  </entity-card>
</template>

<script>
import EntityCard from '@/components/entity-card';
import DeviceCardHeaderStatusBar from './DeviceCardHeaderStatusBar';
import DatasetCardHeaderStatusBar from './DatasetCardHeaderStatusBar';

import { computed, onMounted, provide, toRefs } from '@vue/composition-api';
import useHistoryStack from '@/compositions/history-stack';
import { useLinkedObject } from '@/modules/linked-objects/compositions/linkedObject';
import { useLinkedObjectMenu } from '@/modules/linked-objects/compositions/linkedObjectMenu';

export default {
  name: 'LinkedObjectCard',
  components: {
    EntityCard,
    DeviceCardHeaderStatusBar,
    DatasetCardHeaderStatusBar
  },
  props: {
    linkedObjectId: {
      type: [String, Number],
      default: ''
    }
  },
  setup(props, { emit }) {
    const { linkedObjectId } = toRefs(props);

    const { hasPrevRoute: canBack } = useHistoryStack();

    const { load, item, loading } = useLinkedObject(linkedObjectId);

    provide('linkedObject', item);

    const { genMenu } = useLinkedObjectMenu();

    const tabs = [
      {
        id: 'general',
        title: 'General'
      },
      {
        id: 'properties',
        title: 'Properties'
      },
      {
        id: 'controls',
        title: 'Controls'
      },
      {
        id: 'notifications',
        title: 'Notifications'
      }
    ];
    const tabsItems = [
      () => import('./general/index'),
      () => import('./properties/index'),
      () => import('./controls/index'),
      {
        component: () =>
          import('@/modules/notifications/ui/RecentlyNotificationList'),
        props: { objectId: props.linkedObjectId }
      }
    ];
    const handleClose = () => {
      emit('stack:close');
    };
    const handleBack = () => {
      emit('stack:back');
    };

    onMounted(() => {
      load();
    });

    return {
      tabs,
      actions: computed(() => genMenu(item.value)),
      handleClose,
      tabsItems,
      handleBack,
      canBack,
      loading,
      item
    };
  }
};
</script>

<style></style>
