import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import {
  genMenuItemAccess,
  genMenuItemDisable,
  genMenuItemMute,
  genMenuItemEdit,
  genMenuItemSend,
  genMenuItemShowHistory,
  genMenuItemDownloadNotifications,
  genMenuItemCopy,
  genMenuItemDelete,
  genMenuItemUnlink,
  genMenuItemEnable,
  genMenuItemUnmute,
  genMenuDynamicProperty,
  genMenuItemDivider
} from '@/utils/list-generators';

import { linkedObjectService } from '@/modules/linked-objects/api';
import { objectPropertyService } from '@/modules/common/api';

import router from '@/router';

const { getNextRoute } = useHistoryStack();

import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';
import downloadBlob from '@/utils/downloadBlob';
import useMedia from '@/compositions/media';

export function useLinkedObjectMenu() {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { getNotificationsHistory } = useMedia();

  const genMenu = device => {
    const { id, enabled, muted, relationId, name } = device;

    const generateEditGroup = () => {
      let objectProperties = Object.entries(device)
        .map(property => {
          if (property[1]?.__typename === 'ObjectProperty') return property[1];
        })
        .filter(item => item);

      let uniqGroupName = [];
      let insertedItems = [genMenuItemDivider()];

      if (objectProperties) {
        uniqGroupName = new Set(objectProperties.map(item => item.groupName));
      }

      uniqGroupName.forEach(item => {
        const properties = objectProperties
          .filter(itemProperty => item === itemProperty.groupName)
          .map(itemMap => ({
            ...itemMap
          }));

        insertedItems.push(
          genMenuDynamicProperty(
            () => {
              popup.open({
                component: () =>
                  import('@/components/popup/EditProperties.vue'),
                props: {
                  properties,
                  object: device,
                  objectId: id,
                  onConfirm: value => objectPropertyService.updateValues(value)
                }
              });
            },
            {
              title: `Edit ${item}`
            }
          )
        );
      });

      insertedItems.push(genMenuItemDivider());

      return insertedItems.length > 2 ? insertedItems : [];
    };

    return [
      genMenuItemEdit(() => {
        popup.open({
          component: () =>
            import('@/modules/linked-objects/ui/LinkedObjectEdit.vue'),
          props: {
            linkedObjectId: id
          }
        });
      }),
      genMenuItemAccess(() => {
        popup.open({
          component: () => import('@/components/popup/EditRightsGroup.vue'),
          props: {
            objectId: id
          }
        });
      }),
      enabled
        ? genMenuItemDisable(() => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Disable object?',
                onConfirm: () => linkedObjectService.disable(id)
              }
            });
          })
        : genMenuItemEnable(() => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Enable object?',
                onConfirm: () => linkedObjectService.enable(id)
              }
            });
          }),
      muted
        ? genMenuItemUnmute(() => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Unmute device?',
                onConfirm: () => linkedObjectService.setMuted(id, !muted)
              }
            });
          })
        : genMenuItemMute(() => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Mute device?',
                onConfirm: () => linkedObjectService.setMuted(id, !muted)
              }
            });
          }),
      genMenuItemSend(() => {}, { disabled: true }),
      genMenuItemShowHistory(
        async () => {
          await router.push(
            getNextRoute({
              component: componentAliases.hec,
              props: {
                bench: 30,
                ids: [],
                monitoringItem: {
                  id,
                  name: name,
                  type: 'propertyItem'
                }
              }
            })
          );
        },
        { disabled: false }
      ),
      genMenuItemShowHistory(
        async () => {
          await router.push(
            getNextRoute({
              component: componentAliases.hcs,
              props: {
                bench: 30,
                ids: [id],
                monitoringItem: {
                  id,
                  name: name,
                  type: 'controlItem'
                }
              }
            })
          );
        },
        {
          disabled: false,
          title: 'Show controls history'
        }
      ),
      genMenuItemDownloadNotifications(() => {
        popup.open({
          component: () =>
            import('@/components/history-list-card/HistoryDateRangePopup'),
          props: {
            onSubmit: async range => {
              const data = {
                ids: [id],
                from: `${range.from} 00:00:00`,
                to: `${range.to} 23:59:59`
              };
              await getNotificationsHistory(data).then(res => {
                downloadBlob(
                  res.data,
                  `PixelMonitor_NotificationsHistory_${range.from}-${range.to}.csv`,
                  res.data.type
                );
              });
            }
          }
        });
      }),
      ...generateEditGroup(),
      genMenuItemCopy(async () => copyWithAlert(id), {
        title: 'Copy UUID'
      }),
      genMenuItemDelete(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete device?',
            onConfirm: () => linkedObjectService.delete(id)
          }
        });
      }),
      genMenuItemUnlink(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Unlink device?',
            onConfirm: () => linkedObjectService.unlink(relationId)
          }
        });
      })
    ];
  };
  return {
    genMenu
  };
}
