<template>
  <v-progress-linear v-if="loading" indeterminate color="primary" />
  <div
    v-else
    id="scroll-target"
    ref="scroller"
    v-scroll:#scroll-target="onScroll"
    class="entity-card"
  >
    <entity-card-toolbar
      class="toolbar"
      :title="title"
      :transparent="isTransparentToolbar"
      :show-back-btn="showBackBtn"
      :show-favourite-btn="showFavouriteBtn"
      :actions="actions"
      :is-favourite="isFavourite"
      @click:close="$emit('click:close')"
      @click:back="$emit('click:back')"
      @click:favourite="$emit('click:favourite')"
      @click:action="$emit('click:action', $event)"
    />
    <entity-card-image :src="image" :fallback-src="fallbackImage" />
    <entity-card-header :title="title" class="header">
      <template #statusbar>
        <slot name="header:status-bar" />
      </template>
    </entity-card-header>
    <entity-card-tabs v-model="currentTabIndex" :tabs="tabs" class="tabs" />
    <keep-alive>
      <component
        :is="
          tabsItems[currentTabIndex].component
            ? tabsItems[currentTabIndex].component
            : tabsItems[currentTabIndex]
        "
        v-bind="tabsItems[currentTabIndex].props || {}"
      />
    </keep-alive>
  </div>
</template>

<script>
import EntityCardImage from './EntityCardImage';
import EntityCardToolbar from './EntityCardToolbar';
import EntityCardTabs from './EntityCardTabs';
import EntityCardHeader from './EntityCardHeader';
import { onActivated, ref } from '@vue/composition-api';
export default {
  name: 'EntityCard',
  components: {
    EntityCardImage,
    EntityCardToolbar,
    EntityCardTabs,
    EntityCardHeader
  },
  props: {
    image: {
      type: String,
      default: ''
    },
    fallbackImage: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array,
      default: () => []
    },
    tabsItems: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    showFavouriteBtn: {
      type: Boolean,
      default: false
    },
    isFavourite: {
      type: Boolean,
      default: false
    },
    showBackBtn: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const isTransparentToolbar = ref(true);
    const currentTabIndex = ref(0);
    const onScroll = e => {
      isTransparentToolbar.value = !(e.target.scrollTop >= 220);
    };

    onActivated(() => {
      isTransparentToolbar.value = true;
    });

    return {
      onScroll,
      isTransparentToolbar,
      currentTabIndex
    };
  }
};
</script>

<style lang="sass" scoped>
.entity-card
  width: inherit
  max-height: inherit
  height: 100vh
  overflow: auto
  .toolbar
    position: absolute
    top: 0
    left: 0
    width: inherit
    z-index: 1
  .tabs
    position: sticky
    top: 48px
    z-index: 1
</style>
