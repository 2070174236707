import { computed, ref } from '@vue/composition-api';

export const DEVICE_BATTERY_STATUSES = {
  EMPTY: 'empty',
  FULL: 'full',
  DC: 'dc',
  UNKNOWN: 'unknown'
};

export const DEVICE_STATES = {
  ACTIVE: 'Active',
  DISABLED: 'Disabled'
};

const batteryIcons = {
  [DEVICE_BATTERY_STATUSES.FULL]: '$battery_full',
  [DEVICE_BATTERY_STATUSES.EMPTY]: '$battery_empty',
  [DEVICE_BATTERY_STATUSES.DC]: '$battery_dc',
  [DEVICE_BATTERY_STATUSES.UNKNOWN]: '$battery_unknown'
};

export function useDeviceStatus({
  batteryLow = ref(false),
  dcPower = ref(false)
}) {
  {
    return {
      batteryIcon: computed(() => {
        let icon;
        if (dcPower.value === false) {
          if (batteryLow.value) {
            icon = batteryIcons[DEVICE_BATTERY_STATUSES.EMPTY];
          } else {
            icon = batteryIcons[DEVICE_BATTERY_STATUSES.FULL];
          }
        } else if (dcPower.value === true) {
          icon = batteryIcons[DEVICE_BATTERY_STATUSES.DC];
        } else {
          icon = batteryIcons[DEVICE_BATTERY_STATUSES.UNKNOWN];
        }
        return icon;
      }),

      batteryIconColor: computed(() =>
        batteryLow.value && !dcPower.value ? 'red' : ''
      ),
      statusIcon: '',
      statusIconColor: '',
      // statusIcon: computed(() => DEVICE_STATUS_ICONS[status.value]),
      // statusIconColor: computed(() =>
      //   status.value === DEVICE_STATUSES.NO_RESPONSE ||
      //   status.value === DEVICE_STATUSES.ERROR
      //     ? 'red'
      //     : ''
      // ),
      showBatteryLevel: computed(() => !dcPower.value)
    };
  }
}
