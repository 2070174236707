import { useSnackBar } from './snackBar';
const copy = require('clipboard-copy');

export function useCopy() {
  const { show } = useSnackBar();

  const copyWithAlert = text => {
    copy(text)
      .then(() => show('Copied successfully.'))
      .catch(() => show("Can't copy"));
  };

  return {
    copy,
    copyWithAlert
  };
}
